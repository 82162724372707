// import React from 'react';

// const IconLogo = () => (
//   <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
//     <title>Logo</title>
//     <g transform="translate(-8.000000, -2.000000)">
//       <g transform="translate(11.000000, 5.000000)">
//         <polygon
//           id="Shape"
//           stroke="currentColor"
//           strokeWidth="5"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           points="39 0 0 22 0 67 39 90 78 68 78 23"
//         />
//         <path
//           d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
//           // d="M0 960V0h1920v1920H0V960zm1154 568c165-54 292-198 340-384 20-79 20-239 0-324-41-174-162-327-316-400-184-86-378-76-536 27-247 164-309 579-126 854 141 212 401 305 638 227z"
//           fill="currentColor"
//         />
//       </g>
//     </g>
//   </svg>
// );

// export default IconLogo;
import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);
{
  /* <text textAnchor="middle" alignmentBaseline="middle" fill="currentColor" fontSize="55px" fontFamily="Consolas, serif" x="50%" y="50%">

O
</text> */
}
export default IconLogo;
// import React from "react";

// function IconLogo() {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="1634.667"
//       height="1880"
//       version="1"
//       viewBox="0 0 1226 1410"
//     >
//       <path
//         d="M3115 12354C1438 11399 62 10615 58 10611c-3-3-13-1239-22-2746S17 4774 14 4344l-7-780 3036-1777C4712 810 6084 10 6090 10c13 0 6087 3456 6108 3475 11 10 17 659 34 3529 13 2123 18 3520 12 3525-15 14-6047 3544-6064 3548-8 2-1387-778-3065-1733zm3171-2895c385-37 693-175 934-418 123-125 205-242 294-421 136-273 216-575 262-988 21-188 30-744 15-954-51-711-234-1212-560-1539-291-290-668-429-1166-429-385 0-702 86-970 261-99 65-292 257-362 360-222 325-345 736-393 1308-22 260-11 789 20 1061 116 985 545 1571 1264 1726 188 41 450 54 662 33z"
//         transform="matrix(.1 0 0 -.1 0 1410)"
//       ></path>
//       <path
//         d="M5922 8730c-375-68-615-381-716-935-45-246-51-330-51-735 1-311 4-410 18-515 82-600 273-934 606-1055 154-56 353-64 511-20 405 114 622 514 681 1254 15 193 6 674-16 856-80 664-305 1036-685 1135-77 20-273 28-348 15z"
//         transform="matrix(.1 0 0 -.1 0 1410)"
//       ></path>
//     </svg>
//   );
// }

// export default IconLogo;

// import React from 'react';

// const IconLogo = () => (
//   <svg
//     id="logo"
//     xmlns="http://www.w3.org/2000/svg"
//     role="img"
//     viewBox="0 0 1226 1410"
//   >
//     <title>Logo</title>
//     <g transform="matrix(.1 0 0 -.1 0 1410)">
//       <path
//         d="M3115 12354C1438 11399 62 10615 58 10611c-3-3-13-1239-22-2746S17 4774 14 4344l-7-780 3036-1777C4712 810 6084 10 6090 10c13 0 6087 3456 6108 3475 11 10 17 659 34 3529 13 2123 18 3520 12 3525-15 14-6047 3544-6064 3548-8 2-1387-778-3065-1733zm3171-2895c385-37 693-175 934-418 123-125 205-242 294-421 136-273 216-575 262-988 21-188 30-744 15-954-51-711-234-1212-560-1539-291-290-668-429-1166-429-385 0-702 86-970 261-99 65-292 257-362 360-222 325-345 736-393 1308-22 260-11 789 20 1061 116 985 545 1571 1264 1726 188 41 450 54 662 33z"
//       />
//       <path
//         d="M5922 8730c-375-68-615-381-716-935-45-246-51-330-51-735 1-311 4-410 18-515 82-600 273-934 606-1055 154-56 353-64 511-20 405 114 622 514 681 1254 15 193 6 674-16 856-80 664-305 1036-685 1135-77 20-273 28-348 15z"
//       />
//     </g>
//   </svg>
// );

// export default IconLogo;
